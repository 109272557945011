import styled from 'styled-components'

import CheckedImg from '../assets/images/icons/checked.svg'
import { color } from '../assets/styles/variables'

export const Title = styled.h1`
  font-size: 32px;
  color: ${color.primary};
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
`
export const Address = styled.address`
  color: ${color.primary};
  font-size: 14px;
  font-weight: 700;
  margin-top: 26px;
  font-style: normal;
`
export const Number = styled.span`
  width: 60px;
  min-width: 60px;
  height: 60px;
  background-color: #053592;
  border-radius: 7px;
  font-size: 20px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  font-weight: 700;

`

export const NumberTop = styled(Number)`
  @media screen and (max-width: 991px) {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const TitleArticle = styled.div`
  font-size: 20px;
  color: #053592;
  font-weight: bold;
  display: flex;
  align-items: center;
  display: inline-flex;
  h2 {
    font-size: 20px;
    text-align: center;
  }
  @media screen and (max-width: 991px) {
    text-align: center;
    margin-top: 30px;
    width: 100%;
    display: block;
    h2 {
      text-align: center;
    }
  }
`
export const Checked = styled.div`
  font-size: 24px;
  padding: 0;
  margin: 0;
  color: ${color.primary};
  font-weight: 700;
  &:before {
    content: url(${CheckedImg});
    margin-right: 10px;
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: 2px;
  }
`
export const Description = styled.div`
  color: #99a5bb;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 1rem;
`

export const Box = styled.div`
  background-color: ${color.background};
  border-radius: 7px;
  padding: 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
`

export const ChartBox = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: center;
    padding: 15px;
  }
`

export const LogoWrapper = styled.div`
  height: 40px;
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    width: 110px;
    height: auto;
  }
`
