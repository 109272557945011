import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { color } from '../assets/styles/variables'
import Hero from '../components/Hero/Hero'
import { Heading } from '../components/HowItWork/HowItWork.style'
import Layout from '../components/Layout/Layout'
import LastPosts from '../components/Posts/LastPosts'
import Seo from '../components/Seo/Seo'
import StickyBox from '../components/StickyBox/StickyBox'
import { Box, Number } from '../templates/insuranceCompany.style'
import { ArticleContent } from '../templates/post.style'

export default function HowItWorks() {
  const data = useStaticQuery(query)
  const seo = data.wpPage.seo

  const texts = {
    heading: 'Jak działa nasz ranking?',
    paragraphs: [
      '<strong>Sprawdź, w jaki sposób jest obliczana końcowa ocena<br> dla firmy, która znajduje się w naszym rankingu.</strong>',
      'Możesz zaoszczędzić swój czas, jeśli skorzystasz z naszego rankingu. Przygotowaliśmy wiarygodne zestawienie firm, które pomoże Ci wybrać odpowiednią polisę na życie. Nie wiesz, na jakie ubezpieczenie się zdecydować?',
    ],
  }
  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl} />
      <Hero
        subheading={texts.subheading}
        heading={texts.heading}
        paragraphs={texts.paragraphs}
        img={
          <StaticImage
            src="../assets/images/how-it-work/bg.png"
            loading="eager"
            width={650}
            quality={90}
            data-main-image="true"
            placeholder="none"
            alt="Ranking dobrych polis na życie"
            title="Ranking dobrych polis na życie"
          />
        }
      />
      <Container>
        <Row>
          <Col lg={8}>
            <ArticleContent style={{ marginTop: 0 }}>
              <Row>
                <Col md={1} style={{ position: 'relative' }}>
                  <Number>1</Number>
                </Col>
                <Col md={11} className="px-md-5 px-xl-4">
                  <Heading className="pt-3 pt-lg-0">
                    Pobieramy opinie na temat firm, które są zebrane z różnych witryn internetowych.
                  </Heading>
                  <Box style={{ marginTop: 20 }}>
                    <div className="d-none d-md-block">
                      <StaticImage
                        src="../assets/images/how-it-work/logotypes.png"
                        alt="Logotypy"
                        title="Logotypy firm"
                        quality={90}
                      />
                    </div>
                    <div className="d-block d-md-none d-flex justify-content-center">
                      <StaticImage
                        src="../assets/images/how-it-work/logotypes-mobile.jpg"
                        alt="Logotypy"
                        title="Logotypy firm"
                        quality={90}
                      />
                    </div>
                  </Box>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col md={1} style={{ position: 'relative' }}>
                  <Number>2</Number>
                </Col>
                <Col md={11} className="px-md-5 px-xl-4">
                  <Heading className="pt-3 pt-lg-0">
                    Dla każdego z tych portali zostaje wyliczona średnia ważona. Jest ona liczona według wzoru:
                  </Heading>
                  <Box style={{ marginTop: 20 }}>
                    <StaticImage
                      src="../assets/images/how-it-work/2.png"
                      alt="Wzór średniej ważonej"
                      Title="Wzór średniej ważonej"
                      quality={90}
                    />
                  </Box>
                  <div
                    style={{
                      marginTop: 20,
                      fontSize: 14,
                      color: `${color.primary}`,
                    }}>
                    <strong>H</strong> - współczynnik wiarygodności
                    <br />
                    <strong>X</strong> - podstawa logarytmu
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col md={1} style={{ position: 'relative' }}>
                  <Number>3</Number>
                </Col>
                <Col md={11} className="px-md-5 px-xl-4">
                  <Heading className="pt-3 pt-lg-0">
                    Porządkowane zostają oceny cząstkowe (oceny dla portali) poprzez ich liniową transformację wg wzoru:
                  </Heading>
                  <Box style={{ marginTop: 20 }}>
                    <StaticImage
                      src="../assets/images/how-it-work/3.png"
                      alt="Liniowa transformacja"
                      Title="Liniowa transformacja"
                      quality={90}
                    />
                  </Box>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col md={1} style={{ position: 'relative' }}>
                  <Number>4</Number>
                </Col>
                <Col md={11} className="px-md-5 px-xl-4">
                  <Heading className="pt-3 pt-lg-0">Wyliczona zostaje ocena końcowa wg wzoru:</Heading>
                  <Box style={{ marginTop: 20 }}>
                    <StaticImage
                      src="../assets/images/how-it-work/4.png"
                      alt="Wzór oceny końcowej"
                      Title="Wzór oceny końcowej"
                      quality={90}
                    />
                  </Box>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col md={1} style={{ position: 'relative' }}>
                  <Number>5</Number>
                </Col>
                <Col md={11} className="px-md-5 px-xl-4">
                  <Heading className="pt-3 pt-lg-0">
                    Otrzymany wynik jest porządkowany do przedziału [1,10] przez liniową transformację oceny końcowej wg
                    wzoru:
                  </Heading>
                  <Box style={{ marginTop: 20 }}>
                    <StaticImage
                      src="../assets/images/how-it-work/5.png"
                      alt="Wzór średniej ważonej"
                      Title="Wzór średniej ważonej"
                      quality={90}
                    />
                  </Box>
                  <div
                    style={{
                      marginTop: 20,
                      fontSize: 14,
                      color: `${color.primary}`,
                    }}>
                    <strong>OK_MAX</strong> - maksymalna ocena końcowa uzyskana w rankingu
                    <br />
                    <strong>OK_MIN</strong> - minimalna ocena końcowa uzyskana w rankingu
                    <br />
                    <strong>N_MAX</strong> - przyjęta nowa maksymalna wartość (10)
                    <br />
                    <strong>N_MIN</strong> - przyjęta nowa minimalna wartość (1)
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col md={1} style={{ position: 'relative' }}>
                  <Number>5</Number>
                </Col>
                <Col md={11} className="px-md-5 px-xl-4">
                  <Heading className="pt-3 pt-lg-0">Przyjęte wartości:</Heading>
                  <div
                    style={{
                      marginTop: 20,
                      fontSize: 14,
                      color: `${color.primary}`,
                    }}>
                    <strong>H</strong> - 1.6
                    <br />
                    <strong>X</strong> - 10
                    <br />
                    <strong>WAGA GOOGLE</strong> - 1<br />
                    <strong>WAGA FACEBOOK</strong> - 1<br />
                    <strong>WAGA PANORAMA FIRM</strong> - 1<br />
                    <strong>WYMAGANA LICZBA OCEN DO WIARYGODNOŚCI GOOGLE</strong> - 50 <br />
                    <strong>WYMAGANA LICZBA OCEN DO WIARYGODNOŚCI FACEBOOK</strong> - 50 <br />
                    <strong>WYMAGANA LICZBA OCEN DO WIARYGODNOŚCI PANORAMA FIRM</strong> - 50
                  </div>
                </Col>
              </Row>
            </ArticleContent>
          </Col>
          <Col lg={4}>
            <StickyBox />
          </Col>
        </Row>
        <LastPosts />
      </Container>
    </Layout>
  )
}

const query = graphql`
  {
    wpPage(slug: { eq: "jak-to-dziala" }) {
      seo {
        metaDesc
        title
        focuskw
        opengraphUrl
      }
    }
  }
`
